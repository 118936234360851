import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Container from '../components/global/container';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import PortableText from '../components/portableText';
import Layout from '../containers/layout';

export const query = graphql`
  query PrivacyPageQuery {
    static: sanityPage(slug: { current: { eq: "privacy-policy" } }) {
      _rawHeading
      _rawIntro
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

const PrivacyPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const content = (data || {}).static;

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   );
  // }

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
      {content.seo && (
          <SEO
          title={content.seo.title !== null ? content.seo.title : false}
          description={content.seo.description !== null ? content.seo.description : false}
          keywords={content.seo.keywords !== null ? content.seo.keywords : false}
          image={content.seo.image !== null ? content.seo.image : false}
        />
        )}
        <Container>
          <section className={`section site-wide privacy-policy`} >
            <div>
              {content._rawHeading && <PortableText blocks={content._rawHeading} />}
              {content._rawIntro && <PortableText blocks={content._rawIntro} />}
            </div>
          </section>
        </Container>
      </Layout>
    </>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default PrivacyPage;
